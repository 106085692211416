import React from 'react';
import { Table, Spin } from 'antd';


export default function EntryLog(props) {
    const { fetchingData, LogData, memberList, roomList } = props;
    console.log(fetchingData, LogData, memberList, roomList)
    const columns = [
        {
            title: '成员',
            dataIndex: 'name',
            filters: memberList,
            onFilter: (value, record) => record.name === value,
        },
        {
            title: '口袋房间',
            dataIndex: 'room_owner_name',
            filters: roomList,
            onFilter: (value, record) => record.room_owner_name === value,
        },
        {
            title: '房间类型',
            dataIndex: 'room_type',
            filters: [
                { text: '口袋房间', value: '口袋房间'},
                { text: '直播间', value: '直播间'},
            ],
            onFilter: (value, record) => record.room_type === value,
        },
        {
            title: '类型',
            dataIndex: 'type',
            filters: [
                { text: '进入', value: '进入'},
                { text: '离开', value: '离开'},
                { text: '发言', value: '发言'},
            ],
            onFilter: (value, record) => record.type === value,
        },
        {
            title: '时间',
            dataIndex: 'create_time',
        },
    ];
    return (
        <Spin spinning={fetchingData}>
            <Table columns={columns} dataSource={LogData} rowKey="id" />
        </Spin>
    )
}