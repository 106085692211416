import React, { useEffect, useState } from 'react';
import { Form, Select, PageHeader, Radio, DatePicker, Button, Spin, Tooltip, Space, Checkbox } from 'antd';

const { RangePicker } = DatePicker;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const Querier = (props) => {
    var type = 'room';
    const { master, onQuery, pk } = props;
    const [member_list, setMemberList] = useState([])
    const [initialized, setInitialized] = useState(false)
    const [monthlyQuery, setMonthlyQuery] = useState(true)
    const [form] = Form.useForm();

    useEffect(() => {
        if (initialized) return;
        const url = master ?
            "https://pocket-api.xcookie.org/applet/getMember?master=1" :
            `https://pocket-api.xcookie.org/applet/getMember?pk=${pk}`;

        fetch(url)
            .then(resp => resp.json())
            .then(res => {
                var m = [];
                res.forEach(room => {
                    m.push({ label: room.name, value: room.member_id });
                    //member_list_text[room.id] = room.ownerName + '(ID:' + room.id + ')';
                })
                setMemberList(m);
                setInitialized(true);
            })
    })

    const setType = (new_type) => {
        type = new_type.target.value;
    }

    const query = (v) => {
        onQuery({monthlyQuery, ...v});
    }

    return (
        <>
            <PageHeader
                className="site-page-header-responsive"
                title="数据查询器"
                subTitle="选择对应内容查询相应数据"
                backIcon={false}
                style={{ textAlign: 'left' }}
            >
                <Spin
                    spinning={!initialized}
                    tip="正在初始化"
                >
                    <Form
                        {...layout}
                        form={form}
                        name="query"
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 21 }}
                        onFinish={query}
                        initialValues={{ type: 'member', monthly_query: monthlyQuery }}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="type"
                            label="类型"
                            rules={[{ required: true, message: '请选择查询类型' }]}
                        >
                            <Radio.Group
                                onChange={setType}
                                value={type}
                            >
                                <Radio value={"room"}>口袋房间房间访客记录</Radio>
                                <Radio value={"member"}>成员出入其他房间记录</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            name="target"
                            label="成员/房间"
                            rules={[{ required: true, message: '请选择成员' }]}
                        >
                            <Select
                                options={member_list}
                            />
                        </Form.Item>
                        <Form.Item
                            label="时间范围"
                        >
                            <Space>
                                <Form.Item
                                    name="time_range"
                                    label="时间范围"
                                    rules={!monthlyQuery && [{ required: true, message: '请选择时间范围' }]}
                                    noStyle
                                >
                                    <RangePicker
                                        showTime={true}
                                        showNow={true}
                                        disabled={monthlyQuery}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="monthly_query"
                                    noStyle
                                >
                                    <Tooltip>
                                        <Checkbox
                                            onChange={(v) => setMonthlyQuery(v.target.checked)}
                                            checked={monthlyQuery}
                                        >查询本月</Checkbox>
                                    </Tooltip>
                                </Form.Item>

                            </Space>

                        </Form.Item>
                        <Form.Item {...tailLayout} >
                            <Button type="primary" htmlType="submit">
                                查询
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            </PageHeader>
        </>
    )
}

export default Querier;