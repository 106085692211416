import React from 'react';
import Querier from './components/Querier';
import EntryLog from './components/EntryLog';
import Sensitive from './components/Sensitive';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState } from 'react';
import { message } from 'antd';

const getCurrentMonth = () => {
  const date = new Date(), y = date.getFullYear(), m = date.getMonth();
  const firstDay = new Date(y, m, 1).getTime() / 1000;
  const lastDay = new Date(y, m + 1, 0).getTime() / 1000;
  return { firstDay, lastDay };
}

const App = () => {
  let [fetchingData, setFetchingData] = useState(false);
  let [entryLog, setEntryLog] = useState([]);
  let [roomList, setRoomList] = useState([]);
  let [memberList, setMemberList] = useState([]);

  const doQuery = (query) => {
    console.log(query);
    if (query.type === undefined || query.target === undefined || (!query.monthlyQuery && query.time_range === undefined)) {
      message.error('请完整填写信息');
      return;
    }
    const param = {
      type: query.type,
      target: query.target,
      start_time: !query.monthlyQuery && query.time_range[0].unix(),
      end_time: !query.monthlyQuery && query.time_range[1].unix(),
    }
    if (query.monthlyQuery) {
      const { firstDay, lastDay } = getCurrentMonth();
      param.start_time = firstDay;
      param.end_time = lastDay;
      console.log(param);
    }
    setFetchingData(true);
    console.log(param);
    fetch(`https://pocket-api.xcookie.org/applet/query?type=${param.type}&target=${param.target}&start_time=${param.start_time}&end_time=${param.end_time}`)
      .then(resp => resp.json())
      .then(res => {
        console.log(res)
        setEntryLog(res.record)
        setMemberList(res.member)
        setRoomList(res.room)
        setFetchingData(false);
      })

  }

  document.title = '数据查询';

  return (
    <>
      <title>数据查询</title>
      <div className="App" style={{ padding: '10px' }} >
        <BrowserRouter>
          <Routes>
            <Route element={<Sensitive />} path="/" />
            <Route element={<Querier master={false} onQuery={doQuery} />} path="/entry/*" />
            <Route element={<Querier master={false} pk="gxy" onQuery={doQuery} />} path="/entry/gxy" />
            <Route element={<Querier master={false} pk="szrp" onQuery={doQuery} />} path="/entry/szrp" />
            <Route element={<Querier master={false} pk="w16" onQuery={doQuery} />} path="/entry/w16" />
            <Route element={<Querier master={true} pk="" onQuery={doQuery} />} path="/entry/master" />
          </Routes>
        </BrowserRouter>
        <BrowserRouter>
          <Routes>
            <Route element={<EntryLog
              style={{ margin: '20px' }}
              fetchingData={fetchingData}
              LogData={entryLog}
              memberList={memberList}
              roomList={roomList}
            />} path="/entry/*" />
          </Routes>
        </BrowserRouter>

      </div>
    </>
  )
};

export default App;