import React, { useState } from 'react';
import { PageHeader, Form, Input, Button, Alert, message, Space } from 'antd';
import w from '../lib/sensitive_word';

const { TextArea } = Input;

const layout = {
    labelCol: {
        span: 5,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 5,
        span: 16,
    },
};

const check = (content, setLoading, setBadWords) => {
    setLoading(true);
    const bad_words = [];
    for (const word of w) {
        if (content.text.toLowerCase().indexOf(word) > -1) {
            bad_words.push(word);
        }
    }
    setLoading(false);
    setBadWords(bad_words);
    if (bad_words.length === 0) {
        message.success('检查通过，无敏感词');
        return;
    }
    message.warn('发现敏感词，请根据提示修改');
    console.log(bad_words);
}


const Sensitive = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [badWords, setBadWords] = useState([]);

    return (
        <>
            <PageHeader
                className="site-page-header-responsive"
                title="敏感词查询"
                subTitle="输入你的内容查询对应敏感词"
                backIcon={false}
                style={{ textAlign: 'left' }}
            >
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Alert message="目前使用iOS端敏感词词库。词库更新日期2021年12月26日。" type="info" />
                    <Form
                        {...layout}
                        form={form}
                        name="sensitive"
                        onFinish={(e) => check(e, setLoading, setBadWords)}
                        autoComplete="off"
                    >
                        <Form.Item
                            name="text"
                            label="内容"
                            rules={[{ required: true, message: '请输入内容' }]}
                        >
                            <TextArea
                                autoSize={{ minRows: 4 }}
                                showCount 
                                allowClear
                            />
                        </Form.Item>
                        <Form.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit" loading={loading}>
                                查询
                            </Button>
                        </Form.Item>
                    </Form>
                    {badWords.length > 0 && (
                        <Alert
                            message={"发现敏感词: " + badWords.join(",")}
                            type="error"
                        />
                    )}
                </Space>
            </PageHeader>

        </>
    );
}

export default Sensitive;